<i18n locale="th" lang="yaml" src="@i18n/service/claim.th.yaml">
</i18n>
<i18n locale="th" lang="yaml" >
claim.payment : "การเรียกเก็บเงิน"
claim.payment.description : "สถานะการเรียกเก็บเงินกับทาง HGR"
claim.field.payment_request_remark.placeholder : "เช่น ได้รับเป็นเครดิตจากทาง HGR หรือ กำลังรอทาง HGR และ RCC อยู่ เป็นต้น"
</i18n>

<template>
	<div class="claim-payment-container">
		<div class="mytab-section-title">
			{{$t('claim.payment')}}
			<div class="mytab-section-description">
				{{$t('claim.payment.description')}}
			</div>
		</div>

		<div class="claim-subsection claim-payment">
			<div class="claim-row claim-row-sameline">
				<label>{{$t('claim.field.payment_request')}}</label>
				<div v-if="canUpdate" class="claim-input">
					<a-radio-group v-model="paymentRequestValue.payment_request"
						:options="paymentRequestOptions"
						@change="handleChange"/>
				</div>
				<div v-else class="claim-value">
					{{$tenum('claim.payment_request',paymentRequestValue.payment_request)}}
				</div>
			</div>
			<div v-if="showRequestDate" class="claim-row claim-row-sameline">
				<label>{{$t('claim.field.payment_requested_date')}} *</label>
				<div v-if="canUpdate" class="claim-input">
					<MyDatePicker
						v-model="paymentRequestValue.payment_requested_date"
						format="DD MMMM YYYY"/>
				</div>
				<div v-else class="claim-value">
					{{paymentRequestValue.payment_requested_date | date}}
				</div>
			</div>
			<div v-if="showCompleteDate" class="claim-row claim-row-sameline">
				<label>{{$t('claim.field.payment_completed_date')}} *</label>
				<div v-if="canUpdate" class="claim-input">
					<MyDatePicker
						v-model="paymentRequestValue.payment_completed_date"
						format="DD MMMM YYYY"/>
				</div>
				<div v-else class="claim-value">
					{{paymentRequestValue.payment_completed_date | date}}
				</div>
			</div>

			<div class="claim-row">
				<label>{{$t('claim.field.payment_request_remark')}}</label>
				<div v-if="canUpdate" class="claim-input">
					<a-textarea
						v-model="paymentRequestValue.payment_request_remark"
						class="claim-limit-width"
						:auto-size="{ minRows: 3,maxRows: 5 }"
						:placeholder="$t('claim.field.payment_request_remark.placeholder')"/>
				</div>
				<div v-else class="claim-value">
					<MyTextAreaView :value="paymentRequestValue.payment_request_remark"
						class="claim-limit-width"/>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import {Radio} from "ant-design-vue"
import MyTextAreaView from "@components/common/MyTextAreaView.vue"
import MyDatePicker from "@components/input/MyDatePicker.vue"
import {replaceProperty} from "@utils/objectUtil"
import {getEnumSelectOptions} from "@utils/formUtil"

export default {
	components : {
		"a-radio-group" : Radio.Group ,
		MyTextAreaView, MyDatePicker,
	} ,
	props : {
		service : {
			type : null,
			default : ()=>[]
		} ,
		canUpdate : {
			type : Boolean,
			default : false,
		}
	} ,
	data() {
		return {
			paymentRequestValue : {
				payment_request : undefined,
				payment_request_remark : undefined,
				payment_requested_date : undefined,
				payment_completed_date : undefined,
			}
		}
	} ,
	computed : {
		paymentRequestOptions() {
			return getEnumSelectOptions(this,'claim.payment_request',['none','request','complete'])
		} ,
		showRequestDate() {
			return this.paymentRequestValue.payment_request == 'request' || this.paymentRequestValue.payment_request == 'complete'
		} ,
		showCompleteDate() {
			return this.paymentRequestValue.payment_request == 'complete'
		}
	} ,
	methods : {
		setData(claimRequest) {
			replaceProperty(this.paymentRequestValue,claimRequest)
		} ,
		getData() {
			return {
				... this.paymentRequestValue
			}
		} ,
		handleChange() {
			switch(this.paymentRequestValue.payment_request) {
				case 'none' :
					this.paymentRequestValue.payment_requested_date = undefined
					this.paymentRequestValue.payment_completed_date = undefined
					break
				case 'request' :
					this.paymentRequestValue.payment_completed_date = undefined
					break
			}
		}
	}
}
</script>

<style lang="less" scoped>
@import 'claim.less';
</style>
