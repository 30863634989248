<i18n locale="th" lang="yaml" src="@i18n/service/service-job.th.yaml">
</i18n>
<i18n locale="th" lang="yaml" >
service_part.field.claim.num_request : "ขอเคลม"
service_part.field.claim.num_approved : "อนุมัติ"
service_part.field.claim.num_concluded : "เคลม"
service_part.field.remark_claim : "หมายเหตุเคลม"

service_part.field.remark.placeholder : "หมายเหตุเพิ่มเติม"
service_part.field.remark_claim.placeholder : "รายละเอียดขอเคลม"
service_part.field.old_part.placeholder : "เลือกอะไหล่เดิม"
service_part.field.old_part.custom.placeholder : "พิมพ์ชื่ออะไหล่เดิม"
service_part.part_detail : "SKU : {sku}"
service_part.field.change_mode.placeholder : "เลือก"
service_part.part.unit : "{count} ชิ้น"
service_part.part.unit.zero : "ไม่ระบุ"
service_part.part.claim.unit : "{count} ชิ้น"
service_part.part.claim.unit.zero : " "
</i18n>

<template>
	<div class="part-change-item">
		<div class="part-change-col col-mode">
			<label class="mobile-label">{{$t('service_part.field.change_mode')}}</label>
			<div v-if="canEditChangeMode" class="part-change-input">
				<a-select :value="internalValue.mode" :placeholder="$t('service_part.field.change_mode.placeholder')"
					:options="changeModeOptions"
					@change="handleChange($event,'mode')"/>
			</div>
			<div v-else class="part-change-value">
				{{$tenum('service.part.change_mode',value.mode)}}
			</div>
		</div>

		<div v-col-visible="showNewPart" class="part-change-col col-part">
			<label class="mobile-label">
				{{$t('service_part.field.new_part')}}
				<span v-show="$notEmpty(newPartDetail)" class="part-change-detail">{{newPartDetail}}</span>
			</label>
			<div v-if="canEditPartCol" class="part-change-input">
				<PartSelectWithCustom
					:value="newPartCustom"
					:selectable-only="mode == 'recall'"
					:active-parts-only="true"
					:is-onsite="true"
					@change="handlePartChange($event,'new_part')" />
				<span v-show="$notEmpty(newPartDetail)" class="part-change-detail">{{newPartDetail}}</span>
			</div>
			<div v-else class="part-change-value">
				{{newPartDisplay}}
				<span v-show="$notEmpty(newPartDetail)" class="part-change-detail">{{newPartDetail}}</span>
			</div>
		</div>

		<div v-col-visible="showOldPart" class="part-change-col col-part">
			<label class="mobile-label">
				{{$t('service_part.field.old_part')}}
				<span v-show="$notEmpty(oldPartDetail)" class="part-change-detail">{{oldPartDetail}}</span>
			</label>
			<div v-if="canEditPartCol" class="part-change-input">
				<PartSelectWithCustom
					:value="oldPartCustom"
					:active-parts-only="true"
					:selectable-only="mode == 'recall'"
					:is-onsite="true"
					@change="handlePartChange($event,'old_part')" />
				<span v-show="$notEmpty(oldPartDetail)" class="part-change-detail">{{oldPartDetail}}</span>
			</div>
			<div v-else class="part-change-value">
				{{oldPartDisplay}}
				<span v-show="$notEmpty(oldPartDetail)" class="part-change-detail">{{oldPartDetail}}</span>
			</div>
		</div>

		<div class="part-change-col col-number">
			<label class="mobile-label">{{$t('service_part.field.num_change')}}</label>
			<div v-if="canEditNumChangeCol" class="part-change-input">
				<a-input-number
					:value="internalValue.num_change"
					:min="0"
					:step="1" :precision="0"
					class="myinput-number part-number"
					@change="handleChange($event,'num_change')"/>
			</div>
			<div v-else class="part-change-value">
				{{$tc_my('service_part.part.unit',value.num_change)}}
			</div>
		</div>

		<div v-if="showNumClaimRequestCol" class="part-change-col col-number">
			<label class="mobile-label">{{$t('service_part.field.claim.num_request')}}</label>
			<div v-if="canEditNumClaimRequest" class="part-change-input">
				<a-input-number
					:value="internalValue.num_request"
					:min="0" :max="internalValue.num_change"
					:step="1" :precision="0"
					class="myinput-number part-number"
					@change="handleChange($event,'num_request')"/>
			</div>
			<div v-else class="part-change-value">
				{{$tc_my('service_part.part.claim.unit',value.num_request)}}
			</div>
		</div>
		<div v-if="showNumClaimApprovedCol" class="part-change-col col-number">
			<label class="mobile-label">{{$t('service_part.field.claim.num_approved')}}</label>
			<div v-if="canEditNumClaimApproved" class="part-change-input">
				<a-input-number
					:value="internalValue.num_approved"
					:min="0" :max="internalValue.num_request"
					:step="1" :precision="0"
					class="myinput-number part-number"
					@change="handleChange($event,'num_approved')"/>
			</div>
			<div v-else class="part-change-value">
				{{numApprovedDisplay}}
			</div>
		</div>
		<div v-if="showNumClaimConcludedCol" class="part-change-col col-number">
			<label class="mobile-label">{{$t('service_part.field.claim.num_concluded')}}</label>
			<div class="part-change-value">
				{{$tc_my('service_part.part.unit',value.num_concluded)}}
			</div>
		</div>
		<div v-if="showEditRemarkChangeCol" class="part-change-col col-remark">
			<label class="mobile-label">{{$t('service_part.field.remark')}}</label>
			<div v-if="!isHeaderRow" class="part-change-input">
				<a-input
					:value="internalValue.remark"
					class="part-remark"
					:placeholder="$t('service_part.field.remark.placeholder')"
					@change="handleChange($event.target.value,'remark')"/>
			</div>
		</div>
		<div v-if="showEditRemarkClaimCol" class="part-change-col col-remark">
			<label class="mobile-label">{{$t('service_part.field.remark_claim')}}</label>
			<div v-if="!isHeaderRow" class="part-change-input">
				<a-input
					:value="internalValue.remark_claim"
					class="part-remark"
					:placeholder="$t('service_part.field.remark_claim.placeholder')"
					@change="handleChange($event.target.value,'remark_claim')"/>
			</div>
		</div>
		<div v-if="showViewRemarkChangeCol" v-col-visible="$notEmpty(value.remark)" class="part-change-col col-remark-view">
			<label class="mobile-label">{{$t('service_part.field.remark')}}</label>
			<div class="part-change-value">
				<MyTooltip
					display-mode="white"
					:destroy-tooltip-on-hide="true">
					<div slot="title" v-trim class="multiline">
						{{value.remark}}
					</div>
					<a-icon type="message" class="cursor-pointer"/>
				</MyTooltip>
			</div>
		</div>
		<div v-if="showViewRemarkClaimCol" class="part-change-col col-remark-view">
			<label class="mobile-label">{{$t('service_part.field.remark_claim')}}</label>
			<div class="part-change-value">
				<MyTooltip v-if="$notEmpty(value.remark_claim)"
					display-mode="white"
					:destroy-tooltip-on-hide="true">
					<div slot="title" v-trim class="multiline">
						{{value.remark_claim}}
					</div>
					<a-icon type="message" class="cursor-pointer"/>
				</MyTooltip>
			</div>
		</div>

		<div v-if="showActionCol" class="part-change-col col-action">
			<label class="mobile-label">&nbsp;</label>
			<a-button v-if="!isHeaderRow" type="link" class="text-error part-action" icon="delete" tabindex="-1" @click="onRemove"/>
		</div>
		<div v-if="showErrorCol" class="part-change-col col-error">
			<label class="mobile-label">&nbsp;</label>
			<MyTooltip v-if="$notEmpty(error)"
				display-mode="error"
				:destroy-tooltip-on-hide="true">
				<div slot="title">{{error}}</div>
				<a-icon type="exclamation-circle" class="cursor-pointer" theme="outlined"/>
			</MyTooltip>
		</div>
	</div>
</template>

<script>
import {InputNumber,Input,Select} from "ant-design-vue"
import MyTooltip from "@components/common/MyTooltip.vue"
import PartSelectWithCustom from "@components/part/PartSelectWithCustom.vue"
import {replaceProperty} from "@utils/objectUtil"
import {mapGetters} from "vuex"
import _get from "lodash/get"
import _startsWith from "lodash/startsWith"
import {getEnumSelectOptions} from "@utils/formUtil"
export default {
	components : {
		"a-select" : Select, "a-input-number" : InputNumber,
		"a-input" : Input,
		PartSelectWithCustom ,MyTooltip,
	} ,
	directives : {
		colVisible(el,binding) {
			var value = binding.value;
			if (value) {
				el.dataset.col_visibility = 'visible';
			} else {
				el.dataset.col_visibility = 'hidden';
			}
		}
	} ,
	model : {
		prop: 'value',
		event : 'change'
	} ,
	props : {
		value : {
			type : null,
			default : () => []
		} ,
		canModifyRow : {
			type : Boolean,
			default : false,
			// also delete
		} ,
		readonly : {
			type : Boolean,
			default : false ,
		} ,
		isHeaderRow : {
			type : Boolean,
			default : false,
		} ,
		mode : {
			type : String,
			default : 'default'
			// default, recall, repair, - for input
			// claim-request, num_request editable
			// claim-appeal - num_request editable, num_approved readonly
			// claim-decision  - num_request readonly, num_approved editable
			// claim-concluded - num_request readonly, num_approved readonly
			// claim-display - num_approved readonly
		} ,
		isOnsite: {
			type : Boolean,
			default : false,
		},
	} ,
	data() {
		return {
			error : '',
			validating : false,
			useOldAsNew : false,

			internalValue : {
				key : undefined,
				id : undefined,
				mode : 'change',
				num_change : undefined,
				remark : undefined,

				num_request : undefined,
				num_approved : undefined,
				remark_claim : undefined,

				new_part_mode : 'select',
				new_part_id : undefined,
				new_part_name : undefined,
				old_part_mode : 'select',
				old_part_id : undefined,
				old_part_name : undefined,
			}
		}
	} ,
	computed : {
		...mapGetters('drone',['getPartById']) ,
		editable() {
			return !this.readonly && !this.isHeaderRow
		} ,
		showActionCol() {
			return this.canModifyRow
		} ,
		showErrorCol() {
			return !this.readonly
		} ,
		canEditChangeMode() {
			return this.editable && (this.mode == 'default' || this.mode == 'recall' || this.mode == 'repair')
		} ,
		canEditPartCol() {
			return this.editable && (this.mode == 'default' || this.mode == 'recall'|| this.mode == 'repair')
		} ,
		canEditNumChangeCol() {
			return this.editable && (this.mode == 'default' || this.mode == 'recall' || this.mode == 'repair')
		} ,

		showEditRemarkChangeCol() {
			return !this.readonly && (this.mode == 'default' || this.mode == 'recall' || this.mode == 'repair')
		} ,
		showViewRemarkChangeCol() {
			return !this.showEditRemarkChangeCol
		} ,

		isClaimMode() {
			return _startsWith(this.mode,'claim-') && this.mode != 'claim-display'
		} ,
		canEditNumClaimRequest() {
			return this.editable && (this.mode == 'claim-request' || this.mode == 'claim-appeal')
		} ,
		showNumClaimRequestCol() {
			return this.isClaimMode
		} ,

		canEditNumClaimApproved() {
			return this.editable && (this.mode == 'claim-decision')
		} ,
		showNumClaimApprovedCol() {
			return this.isClaimMode
		} ,

		showNumClaimConcludedCol() {
			return this.mode == 'claim-display'
		},
		showEditRemarkClaimCol() {
			return !this.readonly && (this.mode == 'claim-request' || this.mode == 'claim-decision' || this.mode == 'claim-appeal')
		} ,
		showViewRemarkClaimCol() {
			return this.readonly && (this.isClaimMode  || this.mode == 'claim-display')
		} ,

		changeModeOptions() {
			const options = ['change','remove','add']
			return getEnumSelectOptions(this,'service.part.change_mode',options)
		} ,
		showNewPart() {
			return this.internalValue.mode == 'change' || this.internalValue.mode == 'add'
		} ,
		showOldPart() {
			return this.internalValue.mode == 'change' || this.internalValue.mode == 'remove'
		} ,
		newPartCustom() {
			return {
				mode : this.internalValue.new_part_mode ,
				part_id : this.internalValue.new_part_id,
				part_name : this.internalValue.new_part_name,
			}
		} ,
		newPartDetail() {
			if (this.internalValue.new_part_id > 0) {
				const part = this.getPartById(this.internalValue.new_part_id)
				if (part) {
					return this.$t('service_part.part_detail',{sku : part.sku})
				}
			}
			return null
		} ,
		newPartDisplay() {
			if (this.$notEmpty(this.internalValue.new_part_name)) {
				return this.internalValue.new_part_name
			} else if (this.internalValue.new_part_id > 0) {
				const part = this.getPartById(this.internalValue.new_part_id)
				if (part) {
						return part.name
				}
			}
			return this.$t('common.unspecify')
		} ,
		oldPartCustom() {
			return {
				mode : this.internalValue.old_part_mode ,
				part_id : this.internalValue.old_part_id,
				part_name : this.internalValue.old_part_name,
			}
		} ,
		oldPartDetail() {
			if (this.internalValue.old_part_id > 0) {
				const part = this.getPartById(this.internalValue.old_part_id)
				if (part) {
					return this.$t('service_part.part_detail',{sku : part.sku})
				}
			}
			return null
		} ,
		oldPartDisplay() {
			if (this.$notEmpty(this.internalValue.old_part_name)) {
				return this.internalValue.old_part_name
			} else if (this.internalValue.old_part_id > 0) {
				const part = this.getPartById(this.internalValue.old_part_id)
				if (part) {
						return part.name
				}
			}
			return this.$t('common.unspecify')
		} ,
		numApprovedDisplay() {
			if (this.value.num_request <= 0)
				return ''
			else if (this.value.num_approved <= 0)
				return '-'
			else
				return this.$t('service_part.part.claim.unit',{count : this.value.num_approved})
		} ,
		isEmptyPart() {
			return (
				(this.internalValue.new_part_id == undefined || this.internalValue.new_part_id <= 0) &&
				!this.$notEmpty(this.internalValue.new_part_name) &&
				(this.internalValue.old_part_id == undefined || this.internalValue.old_part_id <= 0) &&
				!this.$notEmpty(this.internalValue.old_part_name) &&
				!this.$notEmpty(this.internalValue.remark) &&
				(this.internalValue.num_change == undefined || this.internalValue.num_change <= 0)
				)
		}
	} ,

	watch : {
		value : {
			handler() {
				this.initialData()
			} ,
			deep : true,
		}
	} ,
	mounted() {
		this.useOldAsNew = false
		this.initialData()
	} ,
	methods : {
		initialData() {
			replaceProperty(this.internalValue,this.value)
			if (this.$notEmpty(_get(this.internalValue,'new_part_name'))) {
				this.internalValue.new_part_mode = 'custom'
			}

			if (this.$notEmpty(_get(this.internalValue,'old_part_name'))) {
				this.internalValue.old_part_mode = 'custom'
			}

			if (this.validating && this.validate()) {
				this.validating = false
			}
		} ,
		validate(revalidate = false) {
			this.validating = true
			this.error = ''
			if (this.isEmptyPart) {
				return true
			}

			let requireNew = false;
			let requireOld = false;
			switch(this.internalValue.mode) {
				case 'change' :
					requireNew = true
					requireOld = true
					break;
				case 'remove' :
					requireOld = true
					break;
				case 'add' :
					requireNew = true
					break;
			}
			let prefix = ''
			if ( requireNew && (
					(this.internalValue.new_part_mode == 'select' && !this.internalValue.new_part_id) ||
					(this.internalValue.new_part_mode == 'custom' && !this.$notEmpty(this.internalValue.new_part_name))
					)) {
				this.error += prefix + this.$t('service_part.field.new_part')
				prefix = ' ,'
			}
			if (requireOld && (
				(this.internalValue.old_part_mode == 'select' && !this.internalValue.old_part_id) ||
				(this.internalValue.old_part_mode == 'custom' && !this.$notEmpty(this.internalValue.old_part_name))
			)) {
				this.error += prefix + this.$t('service_part.field.old_part')
				prefix = ' ,'
			}
			if (this.internalValue.num_change == undefined || this.internalValue.num_change <= 0) {
				this.error += prefix + this.$t('service_part.field.num_change')
				prefix = ' ,'
			}
			if (this.$notEmpty(this.error)) {
				this.error = this.$t('validate.required',{field : this.error})
				return false
			} else {
				return true
			}
		} ,
		onRemove() {
			this.$emit('remove',this.internalValue.key)
		} ,
		handleChange(value,field) {
			if (field == 'mode') {
				switch(value) {
					case 'add' :
						this.internalValue.old_part_mode = 'select'
						this.internalValue.old_part_id = undefined
						this.internalValue.old_part_name = undefined
						break;
					case 'remove' :
						this.internalValue.new_part_mode = 'select'
						this.internalValue.new_part_id = undefined
						this.internalValue.new_part_name = undefined
						break;
				}
			}
			// this[field] = value

			this.$emit('change',{
				...this.internalValue ,
				[field] : value ,
			})
		},
		handlePartChange(value,prefix) {
			const payload = {
				...this.internalValue ,
				[prefix+'_mode'] : value.mode ,
				[prefix+'_id'] : value.part_id,
				[prefix+'_name'] : value.part_name
			}
			if (prefix =='new_part') {
				if (this.internalValue.mode == 'change') {
					// auto assign to oldPart and default num = 1
					if (
						this.useOldAsNew ||
						(this.internalValue.old_part_mode == 'select' && !this.internalValue.old_part_id) ||
						(this.internalValue.old_part_mode == 'custom' && !this.$notEmpty(this.internalValue.old_part_name))
					) {
						payload.old_part_mode = value.mode
						payload.old_part_id = value.part_id
						payload.old_part_name = value.part_name
						this.useOldAsNew = true
					}
					if (this.internalValue.num_change == undefined) {
						payload.num_change = 1
					}
				}
			} else {
				this.useOldAsNew = false
			}
			this.$emit('change',payload)
		} ,
	}

}
</script>

<style lang="less" scoped>
@part-break-screen : @screen-lg;
.part-change-item {
	display : flex;
	flex-wrap: wrap ;
	padding : 12px 16px;
	border-bottom : 1px dashed @primary-color;
	position : relative;

	@media(min-width : @part-break-screen) {
		padding : 12px 8px;
		border-bottom : none;
	}
}
.part-change-col {
	position:  relative;
	margin-right : 8px;
	margin-bottom : 8px;
	label {
		display : block;
		margin-bottom : 2px;
		color : @info-color;
	}
	@media(min-width : @part-break-screen) {
		.part-item-view & {
			margin-bottom : 0;
		}
	}
	.part-change-body &[data-col_visibility=hidden] {
		visibility: hidden;
		@media (max-width : @part-break-screen - 1) {
			display : none;
		}
	}
}
.part-change-detail {
	color : @text-muted;
	font-size : 0.85em;

}
.part-change-value {
	align-self:center;
	display : block;
	color : @primary-color;
	padding-right : 8px;
}



.col-mode::v-deep {
 width : 85px;
 .ant-select {
	width : 100%;
 }
}

.col-part::v-deep {
	width : 230px;
	.part-change-input .part-change-detail {
		position : absolute;
		bottom : -18px;
		left : 0;
		padding : 0;
		margin : 0;
	}

	.part-change-value .part-change-detail {
		padding-top : 2px;
		display : block;
	}
	> label .part-change-detail {
		margin-left : 8px;
	}

	.myselect-part-input {
		width : 200px !important;
		font-size : 0.85em;
	}
	.myselect-part-btn {
		width : 30px !important;
	}

	@media (max-width : @part-break-screen - 1) {
		width : 100%;

		.part-change-input .part-change-detail ,
		.part-change-value .part-change-detail {
			display : none;
		}
	}
}

.col-number::v-deep {
	width : 55px;
	align-self: center;
	.myinput-number.ant-input-number {
		width : 55px;
	}
	input {
		font-size : 0.9em;
	}

}


.col-remark {
	width : 150px;
	max-width : 150px;
	input {
		width : 150px;
		max-width : 150px;
		font-size : 0.85em;
	}
}
.col-remark-view {
	width : 95px;
	text-align : center;
	align-self: center;
}
.col-action {
	width : 32px;
	align-self: center;
}
.col-error {
	color : @error-color;
	width : 24px;
	align-self: center;

}
</style>


<style lang="less">
@part-break-screen : @screen-lg;
.myselect-part-dropdown {
	width : 230px !important;
	@media (max-width : @part-break-screen - 1) {
		width : auto imp !important;
	}
}
</style>
