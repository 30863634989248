<i18n locale="th" lang="yaml" src="@i18n/service/claim.th.yaml">
</i18n>
<i18n locale="th" lang="yaml" >
claim.claim_parts : "อะไหล่ที่ทำการยื่นเคลม"
claim.claim_parts.description : "สามารถเคลมได้เฉพาะอะไหล่ที่มีเลข SKU ในระบบนี้เท่านั้น (ถ้ารายการไหนไม่มีรบกวนติดต่อทาง HGR เพื่อนำข้อมูลเข้าระบบ)"

claim.recall_parts : "อะไหล่ที่ถูกเรียกคืน"
claim.recall_parts.description : "เป็นรายการอะไหล่ที่ถูกเรียกคืน ซึ่งควรจะตรงกับรายการที่ทาง HGR กำหนดเท่านั้น"

enum.claim.part.list_mode.default : "แสดงที่เคลมได้ทั้งหมด"
enum.claim.part.list_mode.requested-only : "เฉพาะที่ขอเคลม"
enum.claim.part.list_mode.approved-only : "เฉพาะที่อนุมัติ"
</i18n>

<template>
	<div class="claim-parts-container">
		<div class="mytab-section-title">
			{{$t('claim.claim_parts')}}
			<div class="mytab-section-description">
				{{$t('claim.claim_parts.description')}}
			</div>
		</div>

		<div class="claim-subsection">
			<a-select v-model="listMode" :options="listModeOptions" class="part-display-select"/>

			<ServicePartList
				ref="claimPartsRef"
				:list-mode="listMode"
				:readonly="!canUpdate"
				:service="service"
				:mode="partMode"/>
		</div>

		<template v-show="showRecallParts">
			<div class="mytab-section-title">
				{{$t('claim.recall_parts')}}
				<div class="mytab-section-description">
					{{$t('claim.recall_parts.description')}}
				</div>
			</div>
			<div class="claim-subsection">
				<ServicePartList
					ref="recallPartsRef"
					:service="service" :readonly="true"/>
			</div>
		</template>

	</div>
</template>

<script>
import {Select} from "ant-design-vue"
import ServicePartList from "@components/service/ServicePartList.vue"
import {getEnumSelectOptions} from "@utils/formUtil"

export default {
	components : {
		"a-select" : Select,
		ServicePartList ,
	} ,
	props : {
		service : {
			type : null,
			default : () => []
		} ,
		canUpdate : {
			type : Boolean ,
			default : false,
		}
	} ,
	data() {
		return {
			listMode : 'default',
			partMode : 'claim-display',
			showRecallParts : false,
		}
	} ,
	computed : {
		listModeOptions() {
			return getEnumSelectOptions(this,'claim.part.list_mode',
				['default','requested-only','approved-only']
				)
		} ,
	},
	methods : {
		setData(claimRequest,serviceParts) {
			switch (claimRequest.status) {
				case 'draft' :
					this.listMode = 'default'
					this.partMode = 'claim-request'
					break;
				case 'draft_appeal' :
					this.listMode = 'default'
					this.partMode = 'claim-appeal'
					break;
				case 'request' :
				case 'appeal' :
					this.listMode = 'requested-only'
					this.partMode = 'claim-decision'
					break;
				case 'approved' :
					this.listMode = 'approved-only'
					this.partMode = 'claim-concluded'
					break;
				case 'rejected' :
					this.listMode = 'requested-only'
					this.partMode = 'claim-concluded'
					break;
				default :
					this.listMode = 'default'
					this.partMode = 'claim-display'
					break;
			}
			if ('repair' in serviceParts) {
				this.$refs.claimPartsRef.setData(serviceParts.repair)
			} else {
				this.$refs.claimPartsRef.setData([])
			}
			if ('recall' in serviceParts) {
				this.showRecallParts = true
				this.$refs.recallPartsRef.setData(serviceParts.recall)
			} else {
				this.showRecallParts = false
				this.$refs.recallPartsRef.setData([])
			}
		} ,
		getData() {
			return this.$refs.claimPartsRef.getData()
		}
	}
}
</script>

<style lang="less" scoped>
@import 'claim.less';
.part-display-select {
	display : block;
	margin-bottom : 8px;
	width : 200px;
}
</style>
