<i18n locale="th" lang="yaml" >
claim.tickets : "Ticket ที่เกี่ยวข้อง"
claim.tickets.description : "รายการ Ticket ที่เกี่ยวข้องกับใบงานนี้ โดยอาจจะมีการแนบ Ticket ที่สอบถามการเคลมไว้เพื่อความสะดวกกับการตรวจสอบของ HGR ครับ"
claim.tickets.empty : "ยังไม่ได้ระบุ Ticket ที่เกี่ยวข้อง"
claim.tickets.manage.button : "ระบุ Ticket ที่เกี่ยวข้อง"
</i18n>

<template>
	<div class="claim-files-container">
		<div class="mytab-section-title">
			{{$t('claim.tickets')}}
			<div class="mytab-section-description">
				{{$t('claim.tickets.description')}}
			</div>
		</div>
		<div class="claim-subsection">
			<ul v-if="$notEmpty(linkTickets)" class="ticket-list">
				<li v-for="linkTicket in linkTickets" :key="linkTicket.ticket_id" class="ticket-row">
					<my-router-link name="ticket/view" :param-value="linkTicket.ticket_id" new-window>
						{{linkTicket.ticketData.ticket_no}}
					</my-router-link>
					:
					<span :title="linkTicket.ticketData.topic">
						{{linkTicket.ticketData.topic}}
					</span>
				</li>
			</ul>
			<div v-else class="ticket-list-empty">
				{{$t('claim.tickets.empty')}}
			</div>
			<div v-if="canUpdate" class="ticket-add">
				<a-button @click="handleClickAdd">
					{{$t('claim.tickets.manage.button')}}
				</a-button>
			</div>
		</div>
		<ServiceLinkTicketModal
			v-if="canUpdate"
			ref="linkModalRef"
			:service="service"
			@change="handleChangeTickets"/>
	</div>
</template>

<script>
import ServiceLinkTicketModal from "@components/service/ServiceLinkTicketModal.vue"
export default {
	components : {
		ServiceLinkTicketModal,
	} ,
	props : {
		service : {
			type : null,
			default : () => []
		} ,
		linkTickets : {
			type : null,
			default : () => []
		} ,
		canUpdate : {
			type : Boolean,
			default : false
		}
	} ,
	methods : {
		handleClickAdd() {
			if (this.$refs.linkModalRef) {
				this.$refs.linkModalRef.openModal()
			}
		} ,
		handleChangeTickets(payload) {
			this.$emit("changeLinkTickets",payload)
		} ,
	}
}
</script>

<style lang="less" scoped>
@import 'claim.less';
.ticket-list-empty {
	padding : 16px 0;
	color : @error-color;
}
.ticket-list {
	list-style: none;
	max-width: 1024px;
	.ticket-row {
		padding : 8px 4px;
		border-bottom : 1px dashed @border-color-base;
		&:nth-child(even) {
			background : @gray-3;
		}
	}
}
</style>
