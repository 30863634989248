<i18n locale="th" lang="yaml">
	part_select.page.title : "รายการอะไหล่ที่สามารถเลือกได้"
	part_select.search_keyword.placeholder : "ค้นหาจาก SKU หรือ ชื่อ"
</i18n>

<template>
	<a-modal centered
	:visible="visible"
	width="90%"
	@cancel="onHideModal">
		<my-page-header class="main-header" :title="$t('part_select.page.title')" />
		<div class="search-input">
			<a-input v-model="searchKeyword"
				:placeholder="$t('part_select.search_keyword.placeholder')"
				style="width:250px;"
				allow-clear >
				<a-icon slot="suffix" type="search" />
			</a-input>
		</div>
		<a-list item-layout="vertical" :data-source="filterParts" :pagination="pagination" :loading="loading" class="service-part-list">
				<ServicePartListModalCard
				slot="renderItem"
				slot-scope="part"
				:part="part"
				:on-select-part="handleSelectPart"/>
		</a-list>
		<template v-slot:footer />
	</a-modal>
</template>

<script>

import { List, Modal } from "ant-design-vue"
import ServicePartListModalCard from "@components/service/ServicePartListModalCard.vue"

export default {
	components: {
		'a-modal': Modal,
		'a-list': List,
		ServicePartListModalCard
	},
	props: {
		bordered: {
			type: Boolean ,
			default : true,
		},
		loading: {
			type: Boolean,
			default : false,
		},
		parts: {
			type : Array,
			default: () => []
		},
		visible: {
			type: Boolean,
			default : false,
		},
		onHideModal: {
			type: Function,
			default: () => true,
		},
		onSelectPart: {
			type: Function,
			default: () => {},
		},
	},
	data() {
		return {
			pagination: {
				pageSize: 5,
				current: 1,
				hideOnSinglePage: true,
				onChange: (page) => {
					this.pagination.current = page
				},
			},
			searchKeyword: ''
		}
	},
	computed: {
		filterParts(){
			if(!this.parts?.[0]) return []

			return this.parts.filter(part => {
				const searchByPartSKU = part.sku && part.sku.includes(this.searchKeyword)
				const searchByPartName = part.name && part.name.includes(this.searchKeyword)
				const searchByPartNameEn = part.name_en && part.name_en.includes(this.searchKeyword)
				return searchByPartSKU || searchByPartName || searchByPartNameEn
			})
		}
	},
	methods: {
		handleSelectPart(partId){
			this.onSelectPart(partId)
			this.onHideModal()
		},
	}
}
</script>

<style lang="less" scoped>
.main-header{
	text-align: center;
}
.search-input{
	margin: 10px 0px;
}
</style>
