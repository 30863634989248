<i18n locale="th" lang="yaml" src="@i18n/service/service-job.th.yaml">
</i18n>
<i18n locale="th" lang="yaml" >
service_part.field.claim.num_request : "ขอเคลม"
service_part.field.claim.num_approved : "อนุมัติ"
service_part.field.claim.num_concluded : "เคลม"

service_part.list.add : "เพิ่มรายการอะไหล่"
service_part.empty : "ไม่มีรายการอะไหล่"
</i18n>

<template>
	<div :class="cssList">
		<div class="part-change-header">
			<ServiceOnfieldPartListRow
				v-if="onfieldCol"
				:mode="mode"
				:can-modify-row="canModifyRow"
				:readonly="readonly"
				is-header-row />
			<ServicePartListRow
				v-else
				:mode="mode"
				:can-modify-row="canModifyRow"
				:readonly="readonly"
				:is-onsite="true"
				is-header-row />
		</div>
		<div class="part-change-body">
			<SlideXLeftTransition v-if="$notEmpty(displayParts) && onfieldCol" group tag="div">
				<ServiceOnfieldPartListRow
					v-for="part of displayParts" :key="part.key" ref="partInputs"
					:value="part"
					:class="cssRow"
					:can-modify-row="canModifyRow"
					:readonly="readonly"
					:mode="mode"
					@claim="claimCheck"
					@change="handlePartChange"
					@remove="handleDeletePart"/>
			</SlideXLeftTransition>
			<SlideXLeftTransition v-else-if="$notEmpty(displayParts)" group tag="div">
				<ServicePartListRow
					v-for="part of displayParts" :key="part.key" ref="partInputs"
					:value="part"
					:class="cssRow"
					:can-modify-row="canModifyRow"
					:readonly="readonly"
					:mode="mode"
					@change="handlePartChange"
					@remove="handleDeletePart"/>
			</SlideXLeftTransition>
			<div v-else class="part-change-empty">
				{{$t('service_part.empty')}}
			</div>
		</div>

		<div v-if="canModifyRow" class="part-change-action">
			<a-button icon="plus-o" type="info" @click="handleAddPart">{{$t('service_part.list.add')}}</a-button>
		</div>
	</div>
</template>

<script>
import ServicePartListRow from "@components/service/ServicePartListRow.vue"
import ServiceOnfieldPartListRow from "@components/service/ServiceOnfieldPartListRow.vue"
import {SlideXLeftTransition} from "vue2-transitions"
import {isStringEmpty} from "@utils/stringUtil"
import _uniqueId from "lodash/uniqueId"
import _startsWith from "lodash/startsWith"

export default {
	components : {
		SlideXLeftTransition , ServicePartListRow , ServiceOnfieldPartListRow
	} ,
	props : {
		service : {
			type : null,
			default : () => []
		} ,
		canModifyRow : {
			type : Boolean,
			default : false,
			// also delete
		} ,
		readonly : {
			type : Boolean,
			default : false ,
		} ,
		mode : {
			type : String,
			default : 'default'
			// default, repair,
			// claim-request,  claim-appeal
			// claim-decision,
			// claim-concluded
		} ,
		listMode : {
			type : String,
			default : 'default'
			// default, requested-only, approved-only
		} ,
		onfieldRequested : {
			type : Boolean,
			default : false,
		} ,
		isOnsite: {
			type : Boolean,
			default : false,
		},
	} ,
	data() {
		return {
			parts : [],
			deletedParts : [] ,
		}
	} ,
	computed : {
		onfieldCol() {
			return this.onfieldRequested
		} ,
		cssList() {
			return "part-change-list "+ (this.readonly ? 'part-change-list-view' : 'part-change-list-edit' )
		} ,
		cssRow() {
			return "part-item "+ (this.readonly ? 'part-item-view' : 'part-item-edit' )
		} ,
		showActionCol() {
			return this.canModifyRow
		} ,
		displayParts() {
			return this.parts.filter((p) => {
				switch (this.listMode) {
					case 'requested-only' :
						return p.num_request > 0
					case 'approved-only' :
						return p.num_approved > 0
					default :
						return true
				}
			})
		} ,
	} ,
	methods : {
		isPartDisplay(part) {
			if (!_startsWith(this.mode,'claim-'))
					return true
			if (part.mode == 'change') {
				return (part.new_part_id > 0 && part.old_part_id > 0)
			} else if (part.mode == 'add') {
				return part.new_part_id > 0
			} else {
				return false
			}
		} ,
		handleAddPart() {
			if (this.canModifyRow) {
				this.parts.push({
					key : _uniqueId('part_input_')
				})
			}
		} ,
		handleDeletePart(key) {
			if (this.canModifyRow) {
				const index = this.parts.findIndex((p) => p.key == key)
				if (index != -1) {
					const deletedPart = this.parts[index]
					if (deletedPart.id && deletedPart.id > 0) {
						this.deletedParts.push(deletedPart)
					}
					this.parts.splice(index,1)
				}
			}
		} ,
		validate() {
			let isSuccess = true
			if ('partInputs' in this.$refs) {
				for(const partInput of this.$refs.partInputs) {
					isSuccess = partInput.validate() && isSuccess
				}
			}
			return isSuccess
		} ,
		setData(data) {
			this.parts = []
			this.deletedParts = []
			for(const part of data) {
				if ('action' in part && part.action == 'remove') {
					this.deletedParts.push(part)
				} else {
					if (this.isPartDisplay(part)) {
						this.parts.push({
							...part ,
							key : _uniqueId('part_input_')
						})
					}
				}
			}
			if (this.parts.length == 0 && this.canModifyRow) {
				this.handleAddPart()
			}
		} ,
		getData() {
			const data = []
			for (const part of this.parts) {
				const partForm = this._getPartForm(part,false)
				if (partForm)
					data.push(partForm)
			}
			for (const part of this.deletedParts) {
				const partForm = this._getPartForm(part,true)
				if (partForm)
					data.push(partForm)
			}
			return data
		} ,
		getParts() {
			return this.parts
		} ,
		_getPartForm(part,isDeleted) {
			let action = ''
			let canEditApproved = false
			let canEditRequest = false
			switch(this.mode) {
				case 'default' :
				case 'recall' :
				case 'repair' :
					if (this.isEmptyPart(part) || isDeleted) {
						if (part.id && part.id > 0) {
							action = 'remove'
						} else {
							return null
						}
					}
					return {
						id : part.id,
						mode : part.mode ,
						new_part_id : (isStringEmpty(part.new_part_name) && !part.new_part_id) ? -1 : part.new_part_id,
						new_part_name : part.new_part_name,
						old_part_id : (isStringEmpty(part.old_part_name) && !part.old_part_id) ? -1 : part.old_part_id,
						old_part_name : part.old_part_name,
						num_change : part.num_change ,
						remark : part.remark,
						action,
					}
				case 'claim-request' :
				case 'claim-appeal' :
					canEditRequest = true
					break
				case 'claim-decision' :
					canEditApproved = true
					break;
				default :
					return null
			}
			if (canEditRequest || canEditApproved) {
				if (this.isEmptyPart(part) || !part.id || isDeleted) {
						return null
				}
				const formData = {
					id : part.id,
					remark_claim : part.remark_claim,
					num_request : part.num_request,
					num_approved : part.num_approved ,
					action,
				}
				return formData
			} else {
				return null;
			}
 		} ,
		handlePartChange(payload) {
			const partIndex = this.parts.findIndex((p) => p.key == payload.key)
			for (const part of this.parts) {
				if (part.key === payload.key)
					part.claim_check = payload.claim_check
			}
			if (partIndex >= 0) {
				this.$set(this.parts,partIndex,payload)
			}
		} ,
		isEmptyPart(part) {
			return (
				(part.new_part_id == undefined || part.new_part_id <= 0) &&
				!this.$notEmpty(part.new_part_name) &&
				(part.old_part_id == undefined || part.old_part_id <= 0) &&
				!this.$notEmpty(part.old_part_name) &&
				!this.$notEmpty(part.remark) &&
				(part.num_change == undefined || part.num_change <= 0)
				)
		},
		claimCheck(val) {
			this.$emit('claim', val)
		}
	}
}
</script>

<style lang="less" scoped>
@part-break-screen : @screen-lg;

.part-change-list {
	max-width : 1200px;
	margin-bottom : 24px;
}
.part-change-action {
	margin-top : 24px;
}
.part-change-empty {
	padding : 16px 0;
	color : @error-color;
	background : @gray-3;
	text-align : center;
}
.part-change-header::v-deep {
	flex-wrap: wrap;
	color : @white;
	display : none;
	background-color: @blue-4;

	.part-change-col {
		margin-bottom : 0;
	}
	label {
		color : @white;
	}
	.part-change-value {
		display : none;
	}

	@media(min-width : @part-break-screen) {
		display : flex;
	}
}
.part-change-body::v-deep {
	display : flex;
	flex-direction: column;
	.part-change-item {
		&:nth-child(even) {
			background-color: @blue-1;
		}
		@media (max-width : @part-break-screen - 1) {
			&:nth-child(odd) {
				background-color: @gray-3;
			}
		}
	}
	@media(min-width : @part-break-screen) {
		.mobile-label {
			display : none;
		}
	}
}
</style>
