<i18n locale="th" lang="yaml" src="@i18n/service/service-job.th.yaml">
</i18n>
<i18n locale="th" lang="yaml" >
onfield_service_part.field.claim.num_request : "ขอเคลม"
onfield_service_part.field.remark_claim : "หมายเหตุเคลม"
onfield_service_part.field.remark_claim.placeholder : "รายละเอียดขอเคลม"
onfield_service_part.part_detail : "SKU : {sku}"
onfield_service_part.part.unit : "{count} ชิ้น"
onfield_service_part.part.claim.unit : "{count} ชิ้น"
</i18n>

<template>
	<div class="part-change-item">
		<div class="part-change-col col-mode">
			<label class="mobile-label">{{$t('Claim')}}</label>
			<div class="part-change-value">
				<a-checkbox
					:checked="internalValue.claim_check" @change="handlClaimCheck">
				</a-checkbox>
			</div>
		</div>
		<div class="part-change-col col-mode">
			<label class="mobile-label">{{$t('service_part.field.change_mode')}}</label>
			<div class="part-change-value">
				{{$tenum('service.part.change_mode',value.mode)}}
			</div>
		</div>

		<div class="part-change-col col-part">
			<label class="mobile-label">
				{{$t('service_part.field.new_part')}}
				<span v-show="$notEmpty(newPartDetail)" class="part-change-detail">{{newPartDetail}}</span>
			</label>
			<div class="part-change-value">
				{{newPartDisplay}}
				<span v-show="$notEmpty(newPartDetail)" class="part-change-detail">{{newPartDetail}}</span>
			</div>
		</div>

		<div class="part-change-col col-part">
			<label class="mobile-label">
				{{$t('service_part.field.old_part')}}
				<span v-show="$notEmpty(oldPartDetail)" class="part-change-detail">{{oldPartDetail}}</span>
			</label>
			<div class="part-change-value">
				{{oldPartDisplay}}
				<span v-show="$notEmpty(oldPartDetail)" class="part-change-detail">{{oldPartDetail}}</span>
			</div>
		</div>

		<div class="part-change-col col-number">
			<label class="mobile-label">{{$t('service_part.field.num_change')}}</label>
			<div class="part-change-value">
				{{$tc_my('onfield_service_part.part.unit',value.num_change)}}
			</div>
		</div>

		<div class="part-change-col col-number">
			<label class="mobile-label">{{$t('onfield_service_part.field.claim.num_request')}}</label>
			<div class="part-change-value">
				<a-input-number
					:value="internalValue.num_request"
					:disabled="!internalValue.claim_check"
					:min="0" :max="internalValue.num_change"
					:step="1" :precision="0"
					class="myinput-number part-number"
					@change="handleChange($event,'num_request')"/>
			</div>
		</div>
		<div class="part-change-col col-remark">
			<label class="mobile-label">{{$t('onfield_service_part.field.remark_claim')}}</label>
			<div class="part-change-value">
				<a-input
					:value="internalValue.remark_claim"
					:disabled="!internalValue.claim_check"
					class="part-remark"
					:placeholder="$t('onfield_service_part.field.remark_claim.placeholder')"
					@change="handleChange($event.target.value,'remark_claim')"/>
			</div>
		</div>
	</div>
</template>

<script>
import {InputNumber,Input,Checkbox} from "ant-design-vue"
import {replaceProperty} from "@utils/objectUtil"
import {mapGetters} from "vuex"
import _get from "lodash/get"
export default {
	components : {
		"a-input-number" : InputNumber,
		"a-input" : Input,
		'a-checkbox': Checkbox,
	} ,
	model : {
		prop: 'value',
		event : 'change'
	} ,
	props : {
		value : {
			type : null,
			default : () => []
		} ,
		readonly : {
			type : Boolean,
			default : false ,
		} ,
		isHeaderRow : {
			type : Boolean,
			default : false,
		} ,
		mode : {
			type : String,
			default : 'default'
			// default, recall, repair, - for input
			// claim-request, num_request editable
			// claim-appeal - num_request editable, num_approved readonly
			// claim-decision  - num_request readonly, num_approved editable
			// claim-concluded - num_request readonly, num_approved readonly
			// claim-display - num_approved readonly
		} ,
	} ,
	data() {
		return {
			error : '',
			validating : false,
			useOldAsNew : false,

			internalValue : {
				key : undefined,
				id : undefined,
				mode : 'change',
				num_change : undefined,
				remark : undefined,

				num_request : undefined,
				num_approved : undefined,
				remark_claim : undefined,

				new_part_mode : 'select',
				new_part_id : undefined,
				new_part_name : undefined,
				old_part_mode : 'select',
				old_part_id : undefined,
				old_part_name : undefined,

				claim_check : undefined,
			}
		}
	} ,
	computed : {
		...mapGetters('drone',['getPartById']) ,
		newPartDetail() {
			if (this.internalValue.new_part_id > 0) {
				const part = this.getPartById(this.internalValue.new_part_id)
				if (part) {
					return this.$t('onfield_service_part.part_detail',{sku : part.sku})
				}
			}
			return null
		} ,
		newPartDisplay() {
			if (this.$notEmpty(this.internalValue.new_part_name)) {
				return this.internalValue.new_part_name
			} else if (this.internalValue.new_part_id > 0) {
				const part = this.getPartById(this.internalValue.new_part_id)
				if (part) {
						return part.name
				}
			}
			return this.$t('common.unspecify')
		} ,
		oldPartDetail() {
			if (this.internalValue.old_part_id > 0) {
				const part = this.getPartById(this.internalValue.old_part_id)
				if (part) {
					return this.$t('onfield_service_part.part_detail',{sku : part.sku})
				}
			}
			return null
		} ,
		oldPartDisplay() {
			if (this.$notEmpty(this.internalValue.old_part_name)) {
				return this.internalValue.old_part_name
			} else if (this.internalValue.old_part_id > 0) {
				const part = this.getPartById(this.internalValue.old_part_id)
				if (part) {
						return part.name
				}
			}
			return this.$t('common.unspecify')
		} ,
		isEmptyPart() {
			return (
				(this.internalValue.new_part_id == undefined || this.internalValue.new_part_id <= 0) &&
				!this.$notEmpty(this.internalValue.new_part_name) &&
				(this.internalValue.old_part_id == undefined || this.internalValue.old_part_id <= 0) &&
				!this.$notEmpty(this.internalValue.old_part_name) &&
				!this.$notEmpty(this.internalValue.remark) &&
				(this.internalValue.num_change == undefined || this.internalValue.num_change <= 0)
				)
		}
	} ,

	watch : {
		value : {
			handler() {
				this.initialData()
			} ,
			deep : true,
		}
	} ,
	mounted() {
		this.useOldAsNew = false
		this.initialData()
	} ,
	methods : {
		initialData() {
			replaceProperty(this.internalValue,this.value)
			if (this.$notEmpty(_get(this.internalValue,'new_part_name'))) {
				this.internalValue.new_part_mode = 'custom'
			}

			if (this.$notEmpty(_get(this.internalValue,'old_part_name'))) {
				this.internalValue.old_part_mode = 'custom'
			}

			if (this.internalValue.num_request) {
				this.internalValue.claim_check = true
			}

			if (this.validating && this.validate()) {
				this.validating = false
			}
		} ,
		validate(revalidate = false) {
			this.validating = true
			this.error = ''
			if (this.isEmptyPart) {
				return true
			}

			let requireNew = false;
			let requireOld = false;
			switch(this.internalValue.mode) {
				case 'change' :
					requireNew = true
					requireOld = true
					break;
				case 'remove' :
					requireOld = true
					break;
				case 'add' :
					requireNew = true
					break;
			}
			let prefix = ''
			if ( requireNew && (
					(this.internalValue.new_part_mode == 'select' && !this.internalValue.new_part_id) ||
					(this.internalValue.new_part_mode == 'custom' && !this.$notEmpty(this.internalValue.new_part_name))
					)) {
				this.error += prefix + this.$t('service_part.field.new_part')
				prefix = ' ,'
			}
			if (requireOld && (
				(this.internalValue.old_part_mode == 'select' && !this.internalValue.old_part_id) ||
				(this.internalValue.old_part_mode == 'custom' && !this.$notEmpty(this.internalValue.old_part_name))
			)) {
				this.error += prefix + this.$t('service_part.field.old_part')
				prefix = ' ,'
			}
			if (this.internalValue.num_change == undefined || this.internalValue.num_change <= 0) {
				this.error += prefix + this.$t('service_part.field.num_change')
				prefix = ' ,'
			}
			if (this.$notEmpty(this.error)) {
				this.error = this.$t('validate.required',{field : this.error})
				return false
			} else {
				return true
			}
		} ,
		handleChange(value,field) {
			if (field == 'mode') {
				switch(value) {
					case 'add' :
						this.internalValue.old_part_mode = 'select'
						this.internalValue.old_part_id = undefined
						this.internalValue.old_part_name = undefined
						break;
					case 'remove' :
						this.internalValue.new_part_mode = 'select'
						this.internalValue.new_part_id = undefined
						this.internalValue.new_part_name = undefined
						break;
				}
			}

			this.$emit('change',{
				...this.internalValue ,
				[field] : value ,
			})
		},
		handlClaimCheck(event) {
			this.internalValue.claim_check = event.target.checked
			if (!this.internalValue.claim_check) {
				this.internalValue.num_request = undefined
				this.internalValue.remark_claim = undefined
				this.$emit('change',{
					...this.internalValue ,
					num_request: null,
					num_approved: null,
					remark_claim: null,
				})
			}
			this.$emit('claim', {[this.internalValue.id]: this.internalValue.claim_check })
		} ,
	}

}
</script>

<style lang="less" scoped>
@part-break-screen : @screen-lg;
.part-change-item {
	display : flex;
	flex-wrap: wrap ;
	padding : 12px 16px;
	border-bottom : 1px dashed @primary-color;
	position : relative;

	@media(min-width : @part-break-screen) {
		padding : 12px 8px;
		border-bottom : none;
	}
}
.part-change-col {
	position:  relative;
	margin-right : 8px;
	margin-bottom : 8px;
	label {
		display : block;
		margin-bottom : 2px;
		color : @info-color;
	}
	@media(min-width : @part-break-screen) {
		.part-item-view & {
			margin-bottom : 0;
		}
	}
	.part-change-body &[data-col_visibility=hidden] {
		visibility: hidden;
		@media (max-width : @part-break-screen - 1) {
			display : none;
		}
	}
}
.part-change-detail {
	color : @text-muted;
	font-size : 0.85em;

}
.part-change-value {
	align-self:center;
	display : block;
	color : @primary-color;
	padding-right : 8px;
}



.col-mode::v-deep {
 width : 85px;
 .ant-select {
	width : 100%;
 }
}

.col-part::v-deep {
	width : 230px;
	.part-change-input .part-change-detail {
		position : absolute;
		bottom : -18px;
		left : 0;
		padding : 0;
		margin : 0;
	}

	.part-change-value .part-change-detail {
		padding-top : 2px;
		display : block;
	}
	> label .part-change-detail {
		margin-left : 8px;
	}

	.myselect-part-input {
		width : 200px !important;
		font-size : 0.85em;
	}
	.myselect-part-btn {
		width : 30px !important;
	}

	@media (max-width : @part-break-screen - 1) {
		width : 100%;

		.part-change-input .part-change-detail ,
		.part-change-value .part-change-detail {
			display : none;
		}
	}
}

.col-number::v-deep {
	width : 55px;
	align-self: center;
	.myinput-number.ant-input-number {
		width : 55px;
	}
	input {
		font-size : 0.9em;
	}

}

.col-remark {
	width : 150px;
	max-width : 150px;
	input {
		width : 150px;
		max-width : 150px;
		font-size : 0.85em;
	}
}
</style>

<style lang="less">
@part-break-screen : @screen-lg;
.myselect-part-dropdown {
	width : 230px !important;
	@media (max-width : @part-break-screen - 1) {
		width : auto imp !important;
	}
}
</style>
